<template>
  <div class="split-bill pb-3" style="height: auto">
    <!-- 標題 -->
    <div class="single-page-tag mb-3">
      <p class="sp-start sp-text fw-bolder">拆除帳單
        <a href="https://youtu.be/WqLmb5YKpfo?si=39kfU9QEgZTKUKvJ" target="_blank">
          <img
            class="info-icon"
            src="@/assets/icon/interrogation.png"
          />
        </a>
      </p>
    </div>
    <div class="tw-container">
      <p class="alert alert-danger text-center mb-3">
        請注意 !
        為了避免混淆，拆單過程中，原先的單價並不會因為多件優惠而有所改變~
      </p>
      <!-- 訂單列表 -->
      <div class="table-responsive mb-3" style="height: 400px">
        <table class="table table-hover">
          <thead class="float-header">
            <tr>
              <th>封面</th>
              <th class="text-start" style="min-width: 150px">商品</th>
              <th class="text-start" style="min-width: 150px">款式</th>
              <th style="min-width: 80px">到貨狀況</th>
              <th style="min-width: 80px">明細</th>
              <th style="min-width: 200px">拆單數量</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in merchOrders" :key="data.id">
              <td>
                <Image
                  :imageHash="data.merchandiseImgHash"
                  size="s"
                  v-if="data.merchandiseImgHash"
                  :alt="data.merchandiseImgHash"
                  style="width: 50px; height: 50px"
                ></Image>
                <img
                  src="@/assets/other-images/noImg.png"
                  v-else
                  style="width: 50px; height: 50px"
                />
              </td>
              <td class="text-start">
                {{ data.merchandiseName }}
              </td>
              <td class="text-start">
                {{ data.style }}
              </td>
              <td>
                <p class="fw-bolder" :class="data.textColorForArrialStatus">
                  到貨狀況: {{ data.arrivalStatus }}
                </p>
              </td>
              <td>{{ data.quantity }} X {{ data.price }}</td>
              <td>
                <button
                  class="tw-btn tw0btn-secondary"
                  @click="updateSplitQuantity('min', data)"
                >
                  MIN
                </button>
                <input
                  onwheel="this.blur()"
                  class="w-25"
                  type="number"
                  min="0"
                  @input="listenSplitInput"
                  v-model="data.splitQuantity"
                  pattern="[0-9]*"
                  oninput="this.value=this.value.replace(/\D/g,'')"
                />
                <span> / {{ data.quantity }}&ensp;</span>
                <button
                  class="tw-btn tw0btn-secondary"
                  @click="updateSplitQuantity('max', data)"
                >
                  MAX
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- 顧客備註 -->
      <div class="mb-3 border rounded-3 p-3">
        <p class="tw-border-start fw-bolder mb-3">顧客備註(僅現賣家看到)</p>
        <textarea
          class="form-control"
          cols="10"
          rows="2"
          disabled
          v-model="buyerNote"
        ></textarea>
      </div>
      <!-- 結單資訊 -->
      <CheckoutInfo
        :participantId="participantId"
        :propsToCheckoutInfo="propsToCheckoutInfo"
        @fromCheckoutInfoData="fromCheckoutInfoData"
      ></CheckoutInfo>
      <!-- 發票資訊 -->
      <ReceiptInfo
        v-if="!p_loading && customerCheckoutCanSetReceiptInfo"
        :propsToReceiptInfo="propsToReceiptInfo"
        :propsToCheckoutInfo="propsToCheckoutInfo"
        @fromReceiptInfoData="fromReceiptInfoData"
      ></ReceiptInfo>
      <!-- 金額 -->
      <div class="row">
        <!-- 原帳單 -->
        <div class="col-md-4 p-0 pe-1 mb-md-0 mb-5">
          <div class="mb-3">
            <span class="tw-border-start fw-bolder me-3">原帳單</span>
          </div>
          <div>
            <ul class="fw-bolder">
              <li class="mb-2">
                運費: {{ $methods.numberToFixed(origin.shippingCost) }}元
              </li>
              <li class="mb-2">
                帳單平衡: {{ $methods.numberToFixed(balanceTotal) }}元
              </li>
              <li class="mb-2">
                訂單金額: {{ $methods.numberToFixed(origin.bodyTotal) }}元
              </li>
              <li>購物金折抵: {{ $methods.numberToFixed(origin.money) }}元</li>
            </ul>
            <hr />
            <div class="d-flex justify-content-between">
              <p class="tw-text-size18 text-danger fw-bolder">
                應付金額: {{ $methods.numberToFixed(origin.shouldBePay) }}元
              </p>
            </div>
          </div>
        </div>
        <!-- 拆單後原帳單 -->
        <div class="col-md-4 p-0 pe-1 mb-md-0 mb-5">
          <div class="mb-3">
            <span class="tw-border-start fw-bolder me-3">拆單後原帳單</span>
          </div>
          <div>
            <ul class="fw-bolder">
              <li class="mb-2">
                運費:
                {{ $methods.numberToFixed(afterSplitOrigin.shippingCost) }}元
              </li>
              <li class="mb-2">
                帳單平衡: {{ $methods.numberToFixed(balanceTotal) }}元
              </li>
              <li class="mb-2">
                訂單金額:
                {{ $methods.numberToFixed(afterSplitOrigin.bodyTotal) }}元
              </li>
              <li>
                購物金折抵:
                {{ $methods.numberToFixed(afterSplitOrigin.money) }}元
                <span class="text-danger">(拆單後將會歸0)</span>
              </li>
            </ul>
            <hr />
            <div class="d-flex justify-content-between">
              <p class="tw-text-size18 text-danger fw-bolder">
                應付金額:
                {{ $methods.numberToFixed(afterSplitOrigin.shouldBePay) }}元
              </p>
            </div>
          </div>
        </div>
        <!-- 拆單後 -->
        <div class="col-md-4 p-0 pe-1 mb-md-0 mb-5">
          <div class="mb-3">
            <span class="tw-border-start fw-bolder me-3">拆單後</span>
          </div>
          <div>
            <ul class="fw-bolder">
              <li class="mb-2">
                運費:
                {{
                  $methods.numberToFixed(propsToCheckoutInfo.shippingCost)
                    ? $methods.numberToFixed(propsToCheckoutInfo.shippingCost)
                    : 0
                }}元
              </li>
              <li class="mb-2">帳單平衡: 0元</li>
              <li class="mb-2">
                訂單金額: {{ $methods.numberToFixed(afterSplit.bodyTotal) }}元
              </li>
              <li>
                購物金折抵:
                {{
                  $methods.numberToFixed(propsToCheckoutInfo.money)
                    ? $methods.numberToFixed(propsToCheckoutInfo.money)
                    : 0
                }}元
              </li>
            </ul>
            <hr />
            <div class="d-flex justify-content-between">
              <p class="tw-text-size18 text-danger fw-bolder">
                應付金額: {{ $methods.numberToFixed(afterSplit.shouldBePay) }}元
              </p>
              <div class="form-check p-0" v-if="!p_loading && showDetail">
                <input
                  class="form-check-input m-0 me-2"
                  type="checkbox"
                  value=""
                  id="ShowDetail"
                  v-model="d4showDetail"
                />
                <label class="form-check-label fw-bolder" for="ShowDetail">
                  代客直寄
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="text-end">
      <button class="tw-btn tw-btn-secondary me-3" @click="goBack">返回</button>
      <button class="tw-btn tw-btn-success me-3" @click="splitHeader">
        完成
      </button>
    </div>
  </div>
</template>

<script>
// components
import CheckoutInfo from '../../../components/checkoutOrder/CheckoutInfo.vue'
import ReceiptInfo from '../../../components/checkoutOrder/ReceiptInfo.vue'

export default {
  components: {CheckoutInfo, ReceiptInfo},
  data() {
    return {
      serverToken: '',
      storeId: 0,
      billHeaderId: 0,
      participantId: 0,
      merchOrders: [],
      // 傳給子層(出貨付款資訊)
      propsToCheckoutInfo: {
        selectShppingType: '',
        selectPaymentType: '',
        name: '',
        phone: '',
        selectCity: '',
        selectArea: '',
        address: '',
        shippingCost: 0,
        D4shippingCost: 0,
        note: '',
        money: 0,
        wallet: 0,
      },
      // 傳給子層(發票資訊)
      propsToReceiptInfo: {
        title: '',
        number: '',
        phone: '',
        selectCity: '',
        selectArea: '',
        address: '',
      },
      // 代客直寄
      d4showDetail: false,
      // 顧客備註
      buyerNote: '',
      balanceTotal: 0,
      // 原帳單資訊
      origin: {
        shippingCost: 0,
        bodyTotal: 0,
        money: 0,
        shouldBePay: 0,
      },
      // 拆單後原帳單
      afterSplitOrigin: {
        shippingCost: 0,
        bodyTotal: 0,
        money: 0,
        shouldBePay: 0,
      },
      // 拆單後
      afterSplit: {
        bodyTotal: 0,
        shouldBePay: 0,
      },
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
    this.getStoreInfo()
  },
  computed: {
    // 偏好 > loading
    p_loading() {
      return this.$store.state.Prefer.p_loading
    },
    // 偏好 > 顧客可自行調整結單數量
    customerCanEditCheckoutQuantity() {
      return this.$store.state.Prefer.p_store.customerCanEditCheckoutQuantity[0].selected
    },
    // 偏好 > 結單可填寫發票資訊
    customerCheckoutCanSetReceiptInfo() {
      return this.$store.state.Prefer.p_store.customerCheckoutCanSetReceiptInfo[0].selected
    },
    // 偏好 > 代客直寄
    showDetail() {
      return this.$store.state.Prefer.p_store.showDetail[0].selected
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.billHeaderId = this.$route.params.billHeaderId
      this.participantId = this.$route.query.participantId
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得賣場資訊
    getStoreInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getShippingTypes}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.getBillInfo(storeInfo)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得帳單資訊
    getBillInfo(storeInfo) {
      const vm = this
      const getBillInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        type: 27,
        ids: [this.billHeaderId],
        methods: '{getBuyer,getBalanceMoney,getTotalPrice,getBodyPrice,getBillBodies{getMerchOrder{getMerchandise{getMerchandisePictureLinks},getMerchandiseStyles}},getRecipientPostalCodeDetail,getReceiptPostalCodeDetail}'
      }]
      $.ajax({
        type: 'POST',
        async: true,
        url: getBillInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const billInfo = res.data[0].objects[0]
            vm.sortOutBillInfo(billInfo, storeInfo)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 整理帳單資訊
    sortOutBillInfo(billInfo, storeInfo) {
      this.d4showDetail = billInfo.showDetail
      this.buyerNote = billInfo.buyer.note
      this.balanceTotal = billInfo.balanceMoney ? this.$methods.numberToFixed(billInfo.balanceMoney) : 0
      const shippingCost = billInfo.shippingCost ? this.$methods.numberToFixed(billInfo.shippingCost) : 0
      const money = billInfo.walletUseAmount ? this.$methods.numberToFixed(billInfo.walletUseAmount) : 0
      // 紀錄原帳單金額
      this.origin = {
        shippingCost: this.$methods.numberToFixed(shippingCost),
        bodyTotal: this.$methods.numberToFixed(billInfo.bodyPrice),
        money: this.$methods.numberToFixed(money),
        shouldBePay: this.$methods.numberToFixed(shippingCost + billInfo.bodyPrice + billInfo.balanceMoney - money),
      }
      // 紀錄拆單後原帳單金額
      this.afterSplitOrigin = {
        shippingCost: this.$methods.numberToFixed(shippingCost),
        bodyTotal: this.$methods.numberToFixed(billInfo.bodyPrice),
        money: 0,
        shouldBePay: this.$methods.numberToFixed(shippingCost + billInfo.bodyPrice + billInfo.balanceMoney),
      }
      // 紀錄拆單後金額
      this.afterSplit = {
        bodyTotal: 0,
        shouldBePay: this.$methods.numberToFixed(shippingCost - money),
      }
      // 傳給子層(出貨付款資訊)
      let selectShppingType = ''
      if (billInfo.shippingType) {
        storeInfo.shippingTypes.some(item => {
          if (item.name === billInfo.shippingType) return selectShppingType = `${item.name},${item.cost}`
        })
      }
      this.propsToCheckoutInfo = {
        selectShppingType: selectShppingType,
        selectPaymentType: billInfo.paymentType ? billInfo.paymentType : '',
        name: billInfo.recipient ? billInfo.recipient : '',
        phone: billInfo.recipientPhoneNumber ? billInfo.recipientPhoneNumber : '',
        selectCity: billInfo.recipientPostalCode ? billInfo.recipientPostalCodeDetail.City : '',
        selectArea: billInfo.recipientPostalCode ? billInfo.recipientPostalCode : '',
        address: billInfo.receivingAddress ? billInfo.receivingAddress : '',
        shippingCost: billInfo.shippingCost ? billInfo.shippingCost : 0,
        D4shippingCost: billInfo.shippingCost ? billInfo.shippingCost : 0,
        note: billInfo.note ? billInfo.note : '',
        money: billInfo.walletUseAmount ? billInfo.walletUseAmount : 0,
        wallet: billInfo.buyer.wallet,
      }
      // 傳給子層(發票資訊)
      this.propsToReceiptInfo = {
        title: billInfo.receiptTitle ? billInfo.receiptTitle : '',
        number: billInfo.receiptBusinessAdminNumber ? billInfo.receiptBusinessAdminNumber : '',
        phone: billInfo.receiptPhoneNumber ? billInfo.receiptPhoneNumber : '',
        selectCity: billInfo.receiptPostalCode ? billInfo.receiptPostalCodeDetail.City : '',
        selectArea: billInfo.receiptPostalCode ? billInfo.receiptPostalCode : '',
        address: billInfo.receiptAddress ? billInfo.receiptAddress : '',
      }
      // 整理訂單列表
      this.merchOrders = []
      billInfo.billBodies.forEach(item => {
        // 取商品圖
        item.merchandiseImgHash = null
        item.merchOrder.merchandise.merchandisePictureLinks.some(img => {
          if (img.front) return item.merchandiseImgHash = img.imageHash
        })
        // 取商品名
        item.merchandiseName = item.merchOrder.merchandise.name
        // 取款式
        item.style = ''
        item.merchOrder.merchandiseStyles.forEach(style => {
          item.style += `${style.name} `
        })
        // 到貨狀況
        item.arrivalStatus = null
        item.textColorForArrialStatus = 'text-danger'
        if (item.merchOrder.allocatedQuantity === 0) {
          item.arrivalStatus = '未到貨'
          item.textColorForArrialStatus = 'text-secondary'
        } else if (item.merchOrder.quantity === item.merchOrder.allocatedQuantity) {
          item.arrivalStatus = '全到貨'
          item.textColorForArrialStatus = 'text-success'
        } else if (item.quantity === item.merchOrder.quantity && item.merchOrder.allocatedQuantity > 0) {
          item.arrivalStatus = `到貨: ${item.merchOrder.allocatedQuantity}個`
          item.textColorForArrialStatus = 'text-warning'
        } else {
          item.arrivalStatus = '分批結單，無法判斷到貨數量'
          item.textColorForArrialStatus = 'text-danger'
        }
        item.splitQuantity = 0
        this.merchOrders.push(item)
      })
    },
    // 計算金額
    countFinalTotal() {
      // 先處理拆單後的金額 (不用帶帳單平衡)
      this.afterSplit = {
        bodyTotal: 0,
        shouldBePay: 0,
      }
      this.merchOrders.forEach(item => {
        item.splitQuantity = item.splitQuantity > item.quantity ? item.quantity : item.splitQuantity
        const splitQuantity = item.splitQuantity ? item.splitQuantity : 0
        if (splitQuantity > 0) {
          this.afterSplit.bodyTotal += this.$methods.numberToFixed(item.splitQuantity * item.price)
        }
      })
      const shippingCost = this.propsToCheckoutInfo.shippingCost ? this.$methods.numberToFixed(this.propsToCheckoutInfo.shippingCost) : 0
      const money = this.propsToCheckoutInfo.money ? this.$methods.numberToFixed(this.propsToCheckoutInfo.money) : 0
      this.afterSplit.shouldBePay = this.$methods.numberToFixed(shippingCost + this.afterSplit.bodyTotal - money)
      // 在處理拆單後原帳單金額
      this.afterSplitOrigin.bodyTotal = this.$methods.numberToFixed(this.origin.bodyTotal - this.afterSplit.bodyTotal)
      this.afterSplitOrigin.shouldBePay = this.$methods.numberToFixed(this.afterSplitOrigin.shippingCost + this.afterSplitOrigin.bodyTotal + this.balanceTotal - this.afterSplitOrigin.money)
      // 如果原帳單的應付金額 < 0，就要動"購物金折抵金額"，讓他的應付金額變成 0
      if (this.afterSplitOrigin.shouldBePay < 0) {
        this.afterSplitOrigin.shouldBePay = this.$methods.numberToFixed(this.afterSplitOrigin.shippingCost + this.afterSplitOrigin.bodyTotal + this.balanceTotal)
      }
    },
    // 最大 / 最小
    updateSplitQuantity(status, item) {
      if (status === 'min') {
        item.splitQuantity = 0
      } else {
        item.splitQuantity = item.quantity
      }
      this.countFinalTotal()
    },
    // 監聽拆單數量
    listenSplitInput() {
      this.countFinalTotal()
    },
    // 來自出貨付款的訊息 (子層 > 父層)
    fromCheckoutInfoData(object) {
      this.propsToCheckoutInfo = object
      this.countFinalTotal()
    },
    // 拆單
    splitHeader() {
      const vm = this
      const splitHeaderApi = `${process.env.VUE_APP_API}/bill/splitBodiesToNewHeader`
      const header = {
        authorization: this.serverToken
      }
      let originalBodies = []
      this.merchOrders.forEach(item => {
        if (item.splitQuantity > 0) {
          originalBodies.push({
            id: item.id,
            splitQuantity: item.splitQuantity
          })
        }
      })
      if (originalBodies.length === 0) return this.SweetAlert('other', '您尚未輸入任何拆單數量')
      this.$methods.switchLoading('show')
      const data = {
        originalBodies: originalBodies,
        originalHeaderId: this.billHeaderId,
        newHeader: {
          note: this.propsToCheckoutInfo.note,
          shippingType: this.propsToCheckoutInfo.selectShppingType ? this.propsToCheckoutInfo.selectShppingType.split(',')[0] : null,
          shippingCost: this.propsToCheckoutInfo.shippingCost ? this.propsToCheckoutInfo.shippingCost : 0,
          recipient: this.propsToCheckoutInfo.name,
          recipientPhoneNumber: this.propsToCheckoutInfo.phone,
          recipientPostalCode: this.propsToCheckoutInfo.selectArea ? this.propsToCheckoutInfo.selectArea : null,
          receivingAddress: this.propsToCheckoutInfo.address,
          paymentType: this.propsToCheckoutInfo.selectPaymentType,
          walletUseAmount: this.propsToCheckoutInfo.money ? this.propsToCheckoutInfo.money : 0,
          receiptTitle: this.propsToReceiptInfo.title ? this.propsToReceiptInfo.title : null,
          receiptBusinessAdminNumber: this.propsToReceiptInfo.number ? this.propsToReceiptInfo.number : null,
          receiptPhoneNumber: this.propsToReceiptInfo.phone ? this.propsToReceiptInfo.phone : null,
          receiptPostalCode: this.propsToReceiptInfo.selectArea ? this.propsToReceiptInfo.selectArea : null,
          receiptAddress: this.propsToReceiptInfo.address ? this.propsToReceiptInfo.address : null,
          showDetail: this.d4showDetail,
        }
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: splitHeaderApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.$methods.switchLoading('hide')
            vm.goBack()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 返回
    goBack() {
      if (window.history.length === 1) {
        this.$router.push(`/seller/store/${this.storeId}/bill`);
      } else {
        this.$router.back();
      }
    },
  },
}
</script>